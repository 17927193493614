import cn from "classnames";
import { Ratio } from "react-bootstrap";

export default function ({ className, children, alt = false, ...props }) {
  return (
    <Ratio
      {...props}
      className={cn(className, {
        hexagon: alt === false,
        "hexagon-alt": alt === true,
      })}
      aspectRatio={alt ? 112 : 86}
    >
      <>{children}</>
    </Ratio>
  );
}
